import React from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import { ApexOptions } from "apexcharts";

const DailyReservationsChart = () => {
  const apexOpts: ApexOptions = {
    chart: {
      height: 378,
      type: "line",
      offsetY: 10,
    },
    stroke: {
      width: [2, 3],
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    colors: ["#e73028", "#4a81d4"],
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    labels: [
      "01 Jan 2001",
      "02 Jan 2001",
      "03 Jan 2001",
      "04 Jan 2001",
      "05 Jan 2001",
      "06 Jan 2001",
      "07 Jan 2001",
      "08 Jan 2001",
      "09 Jan 2001",
      "10 Jan 2001",
      "11 Jan 2001",
      "12 Jan 2001",
    ],
    xaxis: {
      type: "datetime",
    },
    legend: {
      offsetY: 7,
    },
    grid: {
      padding: {
        bottom: 20,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.75,
        opacityTo: 0.75,
        stops: [0, 0, 0],
      },
    },
    yaxis: [
      {
        title: {
          text: "Toplam Gelir",
        },
      },
      {
        opposite: true,
        title: {
          text: "Rezervasyon Sayısı",
        },
      },
    ],
  };

  const series = [
    {
      name: "Toplam Gelir",
      type: "column",
      data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 873, 1257, 1160],
    },
    {
      name: "Rezerasyon Sayısı",
      type: "line",
      data: [2, 4, 1, 5, 1, 7, 2, 9, 6, 7, 9, 9],
    },
  ];

  return (
    <>
      <Card>
        <Card.Body>
          <div className="float-end d-none d-md-inline-block">
            <div className="btn-group mb-2">
              <button type="button" className="btn btn-xs btn-light">
                Günlük
              </button>
              <button type="button" className="btn btn-xs btn-light">
                Haftalık
              </button>
              <button type="button" className="btn btn-xs btn-secondary">
                Aylık
              </button>
            </div>
          </div>

          <h4 className="header-title mb-3">Rezervasyonlar ve Toplam Gelir</h4>

          <div dir="ltr">
            <Chart
              options={apexOpts}
              series={series}
              type="line"
              height={240}
              className="apex-charts mt-4"
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default DailyReservationsChart;
