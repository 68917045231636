import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import PageTitle from "../../utils/PageTitle";
import { UsersList } from "./components/UsersList";

const breadCrumbItems = [
  {
    label: "Users",
    path: "/users/",
    active: false,
  },
  {
    label: "",
    path: "/",
    active: false,
  },
];

const UsersPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadCrumbItems={breadCrumbItems} title={"Users"} />
              <UsersList />
            </>
          }
        />
        <Route index element={<Navigate to="/users/list" />} />
      </Route>
    </Routes>
  );
};

export default UsersPage;
