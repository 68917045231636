import AsyncSelect from "react-select/async";
import { useEffect, useState } from "react";
import Element, { BaseProps } from "./Element";
import axios from "axios";
import { MultiValue, SingleValue } from "react-select";

export interface AutocompleteOption {
  readonly value: string;
  readonly label: string;
}

type AutocompleteSelectProps = BaseProps & {
  name: string;
  onChange: Function;
  multiple?: boolean;
  autoCompleteToken?: string;
  autoCompleteTokenUrl?: string;
  defaultValue?: string | number | string[] | number[];
  isDisabled?: boolean;
  className?: string;
};
function AutocompleteSelect(props: AutocompleteSelectProps) {
  const [autoCompleteToken, setAutoCompleteToken] = useState("");
  const [value, setValue] = useState<
    SingleValue<AutocompleteOption> | MultiValue<AutocompleteOption>
  >(null);

  useEffect(() => {
    if (props.autoCompleteToken) {
      setAutoCompleteToken(props.autoCompleteToken);
    } else if (!autoCompleteToken && props.autoCompleteTokenUrl) {
      axios.get(props.autoCompleteTokenUrl).then((response) => {
        setAutoCompleteToken(response.data.data);
      });
    }
  }, [props.autoCompleteTokenUrl, autoCompleteToken, props.autoCompleteToken]);

  const promiseOptions = (inputValue: string) =>
    new Promise<AutocompleteOption[]>((resolve) => {
      let postData = {
        token: autoCompleteToken,
        term: inputValue,
        id: null,
      };
      if (inputValue === "" && props.defaultValue) {
        postData.id = props.defaultValue as any;
      }
      axios.post("/autocomplete", postData).then((response) => {
        let options = [];
        if (response.data?.data) {
          for (let item of response.data.data) {
            options.push({
              value: item.id,
              label: item.text,
            });
          }
        }
        if (
          inputValue === "" &&
          props.defaultValue &&
          (props.defaultValue as Array<any>).length !== 0
        ) {
          if (props.multiple) {
            setValue(options);
          } else {
            setValue(options[0]);
          }
        }
        resolve(options);
      });
    });
  return (
    <Element title={props.title} required={props.required}>
      {autoCompleteToken && props.defaultValue !== undefined ? (
        <div
          style={{ width: "100%" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <AsyncSelect
            cacheOptions
            defaultOptions
            className={props.className ?? "w-100"}
            loadOptions={promiseOptions}
            value={value}
            isClearable={!props.required}
            isDisabled={props.isDisabled ?? false}
            isMulti={props.multiple}
            onChange={(option, meta) => {
              if (meta.action === "clear") {
                setValue(null);
                let event = {
                  target: {
                    name: props.name,
                    value: null,
                  },
                };
                props.onChange(event);
              } else {
                setValue(option);
                let data = option as any;
                if (props.multiple) {
                  let values = data.map((item: AutocompleteOption) => {
                    return item.value;
                  });
                  let event = {
                    target: {
                      name: props.name,
                      value: values,
                    },
                  };
                  props.onChange(event);
                } else {
                  let event = {
                    target: {
                      name: props.name,
                      value: data.value,
                    },
                  };
                  props.onChange(event);
                }
              }
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </Element>
  );
}

export default AutocompleteSelect;
