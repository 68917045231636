import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import PageTitle from "../../utils/PageTitle";
import { FeaturesList } from "./components/FeaturesList";

const breadCrumbItems = [
  {
    label: "Features",
    path: "/features/",
    active: false,
  },
  {
    label: "",
    path: "/",
    active: false,
  },
];

const FeaturesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadCrumbItems={breadCrumbItems} title={"Features"} />
              <FeaturesList />
            </>
          }
        />
        <Route index element={<Navigate to="/features/list" />} />
      </Route>
    </Routes>
  );
};

export default FeaturesPage;
