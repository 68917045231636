import { Form } from 'react-bootstrap'
import { PropsWithChildren } from 'react'
import Element, { BaseProps } from './Element'
import { FormControlProps } from 'react-bootstrap/FormControl'
import { AsProp, BsPrefixProps, ReplaceProps } from 'react-bootstrap/helpers'

type TextareaProps = PropsWithChildren<
  Omit<ReplaceProps<'textarea', BsPrefixProps<'textarea'> & BaseProps & FormControlProps>, 'as'> &
    AsProp
>

export default function Textarea(props: PropsWithChildren<TextareaProps>) {
  const { name, title, required, children, ...restProps } = props

  return (
    <Element title={title} required={required}>
      <Form.Control as='textarea' name={name} {...restProps} />
      {children}
    </Element>
  )
}

Textarea.defaultProps = {
  rows: 3,
}
