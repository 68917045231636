import axios, { AxiosResponse } from "axios";
import {
  Property,
  PropertyFormKeys,
  PropertyPostData,
  SelectListItem,
} from "./_models";
import { WithData } from "../../../utils/axiosWithCSRF";

export function getPropertyFromId(
  propertyId: string = "add"
): Promise<Property> {
  const url = `/properties/single/${propertyId}`;
  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: Property }>) => response.data.data);
}

export function getCategoriesOptionsList(): Promise<SelectListItem[]> {
  const url = `/categories/getCategoryList`;
  return axios
    .get(url)
    .then(
      (response: AxiosResponse<WithData<{ value: string; label: string }[]>>) =>
        response.data
    )
    .then((response) =>
      response.data.map((a) => ({
        label: a.label,
        value: a.value,
      }))
    );
}

export function getFeaturesOptionsList(): Promise<SelectListItem[]> {
  const url = `/features/getFeaturesList`;
  return axios
    .get(url)
    .then(
      (response: AxiosResponse<WithData<{ value: string; label: string }[]>>) =>
        response.data
    )
    .then((response) =>
      response.data.map((a) => ({
        label: a.label,
        value: a.value,
      }))
    );
}

export function getRegionsOptionsList(): Promise<SelectListItem[]> {
  const url = `/regions/getRegionList`;
  return axios
    .get(url)
    .then(
      (response: AxiosResponse<WithData<{ value: string; label: string }[]>>) =>
        response.data
    )
    .then((response) =>
      response.data.map((a) => ({
        label: a.label,
        value: a.value,
      }))
    );
}

export function getUsersOptionsList(): Promise<SelectListItem[]> {
  const url = `/getUsersList`;
  return axios
    .get(url)
    .then(
      (response: AxiosResponse<WithData<{ value: string; label: string }[]>>) =>
        response.data
    )
    .then((response) =>
      response.data.map((a) => ({
        label: a.label,
        value: a.value,
      }))
    );
}

export function getPropertyImageUploadKey(): Promise<string> {
  const url = `/properties/getPropertyImageUploadKey`;
  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: string }>) => response.data.data);
}

export function getPropertyFormKeys(
  propertyId: string = "add"
): Promise<PropertyFormKeys> {
  const url = `/properties/save/${propertyId}`;
  return axios
    .get(url)
    .then(
      (response: AxiosResponse<{ data: PropertyFormKeys }>) =>
        response.data.data
    );
}

export function postPropertyForm(
  propertyId: string = "add",
  data: PropertyPostData
): Promise<any> {
  const url = `/properties/save/${propertyId}`;
  try {
    return axios.post(url, data);
  } catch (error) {
    console.error("Error posting property form:", error);
  }
  return Promise.resolve();
}

export function updateImagesWeight(data: any): Promise<any> {
  return axios.post("/property_images/updateImagesWeight", { 'data': data });
}
