import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import config from "../../config";

interface CsrfParams {
  formId: string
  formBuildId: string
  formToken: string
  token: string
}
export type WithData<T> = {
  data: T
}

function createAxiosWithCSRF() {
  const instance = axios.create()
  const axiosPost = instance.post
  instance.defaults.baseURL = config.API_URL; //Axios config üzerinden ayarlanacak!!!

  instance.post = async function post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<R> {
    const { token, ...csrfData } = await axios
      .get<WithData<CsrfParams>>(instance.defaults.baseURL + url)
      .then((res) => res.data.data)

    if (data == null) {
      data = {} as D
    }

    if (config == null) {
      config = {}
    }

    if (config.headers == null) {
      config.headers = {}
    }

    return axiosPost(
      url,
      { ...data, ...csrfData },
      {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }

  return instance
}

const axiosWithCSRF = createAxiosWithCSRF()
export default axiosWithCSRF
