import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { AuthContextType, LoginParams, User } from "./_models";
import { loginPostApi, meApi } from "./_requests";
import config from "../../../../config";

const authProvider = createContext<AuthContextType>(
  {} as unknown as AuthContextType
);
axios.defaults.baseURL = config.API_URL;

const { Provider } = authProvider;

const useAuthProvider = (): [boolean, AuthContextType] => {
  const [initialized, setInitialized] = useState(false);

  const [user, setUser] = useState<User>();
  const [token, setToken] = useState<string | undefined>(() => {
    return localStorage.getItem("@auth/token") ?? undefined;
  });

  const checkAuth = useCallback(async () => {
    if (token == null) {
      setToken(undefined);
      return;
    }

    const meResponse = await meApi(token);
    if (!meResponse) {
      setToken(undefined);
      return;
    }

    setUser(meResponse.data);
  }, [token]);

  const logout = useCallback(() => {
    setToken(undefined);
  }, []);

  const login = useCallback(
    async (loginParams: LoginParams) =>
      await loginPostApi(loginParams)
        .then((loginResponse) => setToken(loginResponse.data.token))
        .then(() => true),
    []
  );

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    checkAuth().then(() => setInitialized(true));

    if (!token) {
      setUser(undefined);
      localStorage.removeItem("@auth/token");
      axios.defaults.headers.common = {};
      return;
    }

    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    localStorage.setItem("@auth/token", token);
  }, [logout, checkAuth, token]);

  return [
    initialized,
    {
      login,
      logout,
      user,
      token,
      loggedIn: user != null,
    },
  ];
};

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [initialized, auth] = useAuthProvider();

  //return <Provider value={auth}>{initialized ? children : <LayoutSplashScreen />}</Provider>
  return <Provider value={auth}>{initialized ? children : null}</Provider>;
}

export const useAuth = () => useContext(authProvider);
