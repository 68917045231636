import { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAuth } from './core/AuthProvider'

export function Logout() {
  const { logout } = useAuth()
  useEffect(() => {
    logout()
  }, [logout])

  return (
    <Routes>
      <Route index element={<Navigate to='/auth/login' />} />
    </Routes>
  )
}
