import { Navigate, Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { AuthLayout } from './AuthLayout'
import { AuthProvider } from './core/AuthProvider'

const AuthPage = () => (
  <AuthProvider>
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='registration' element={<Registration />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route index element={<Login />} />
        <Route path='*' element={<Navigate to='/auth' />} />
      </Route>
    </Routes>
  </AuthProvider>
)

export { AuthPage }
