import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import AutocompleteSelect from "../../../utils/Form/Widgets/AutocompleteSelect";
interface PricingFilterProps {
  setProperty: (property: string) => void;
}
const PricingFilter: React.FC<PricingFilterProps> = ({ setProperty }) => {
  const handlePropertyChange = (property: any) => {
    setProperty(property.target.value);
  };
  return (
    <Row className="mb-2">
      <Col sm={4}>
        <AutocompleteSelect
          onChange={handlePropertyChange}
          defaultValue={0}
          multiple={false}
          autoCompleteTokenUrl="/properties/autocomplete"
          className=""
          name={"test"}
        />
      </Col>
    </Row>
  );
};

export default PricingFilter;
