import { Form } from "react-bootstrap";
import Element, { BaseProps } from "./Element";

type SelectProps = BaseProps & {
  name: string;
  options: {
    value: string;
    label: string;
  }[];
  multiple?: boolean;
  defaultValue: string | number | readonly string[];
  onChange: React.FormEventHandler<HTMLSelectElement>;
  placeholder?: string;
  nullElement?: string;
};

export default function Select(props: SelectProps) {
  return (
    <Element title={props.title} required={props.required}>
      <Form.Select
        name={props.name}
        value={props.defaultValue ?? ""}
        onChange={props.onChange}
      >
        {props.nullElement !== null && (
          <option value={""}>
            {typeof props.nullElement === "string"
              ? props.nullElement
              : props.placeholder ?? props.title + " Seçiniz"}
          </option>
        )}
        {props.options.map((option) => (
          <option key={`option_${option.value}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Element>
  );
}
