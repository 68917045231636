import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import SummaryCards from "./components/SummaryCards";
import DailyReservationsChart from "./components/DailyReservationsChart";

const Dashboard = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  /*
   * handle date change
   */
  const onDateChange = (date: Date) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Dashboard</h4>
          </div>
        </Col>
      </Row>
      <SummaryCards />
      <DailyReservationsChart />
    </>
  );
};

export default Dashboard;
