import { ReactNode } from "react";
import { FormGroup, Modal } from "react-bootstrap";
import FormHandler from "../../../utils/Form/FormHandler";
import Field from "../../../utils/Form/Widgets/Field";
import AutocompleteSelect from "../../../utils/Form/Widgets/AutocompleteSelect";
import Select from "../../../utils/Form/Widgets/Select";

type Props = {
  onHide: () => void;
  show: boolean;
  itemIdForUpdate?: string;
  refreshFunction?: Function;
};
class Prices {
  property?: number;
  start_date?: string;
  end_date?: string;
  price?: string;
  discounted_price?: string;
  deposit?: string;
  min_duration_date?: string;
  cleaning_service_price?: string;
  free_cleaning_service_min_date?: string;
  partial_paid_rate?: string;
  status?: string;
}
class PricesEditModal extends FormHandler {
  constructor(props: Props) {
    super(props);
    this.state = { entity: new Prices() };
  }

  pricesStatus = [
    {
      value: "0",
      label: "Pasif",
    },
    {
      value: "1",
      label: "Aktif",
    },
  ];

  getApiUrl(): string {
    return "/prices/save/" + this.props.itemIdForUpdate;
  }
  getSingleUrl() {
    if (this.props.itemIdForUpdate === "add") {
      return null;
    } else {
      return "/prices/single/" + this.props.itemIdForUpdate;
    }
  }

  getPostData(): object {
    return {
      prices: this.state.entity,
    };
  }
  render(): ReactNode {
    var state = this.state as any;
    let defaultPropertyValue = state.entity.property || 0;
    if (this.props.itemIdForUpdate === "add") {
      defaultPropertyValue = 0;
    } else {
      defaultPropertyValue = state.entity.property;
    }
    return (
      <Modal
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered"
        size="lg"
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id);
              }
              this.props.onHide();
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.itemIdForUpdate === "add"
                ? "Fiyatlandırma Ekle"
                : "Fiyatlandırmayı Düzenle"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <AutocompleteSelect
                title="Mülk"
                name="property"
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={defaultPropertyValue}
                multiple={false}
                autoCompleteTokenUrl="/properties/autocomplete"
              />

              <Field
                title="Giriş Tarihi"
                className="form-control"
                type="date"
                name="start_date"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.start_date}
                readOnly={this.props.itemIdForUpdate !== "add" ? true : false}
              />
              <Field
                title="Çıkış Tarihi"
                className="form-control"
                type="date"
                name="end_date"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.end_date}
                readOnly={this.props.itemIdForUpdate !== "add" ? true : false}
              />

              <Field
                title="Fiyat"
                className="form-control"
                type="text"
                name="price"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.price}
              />

              <Field
                title="İndirimli Fiyat"
                className="form-control"
                type="text"
                name="discounted_price"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.discounted_price}
              />
              <Field
                title="Depozito"
                className="form-control"
                type="text"
                name="deposit"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.deposit}
              />
              <Field
                title="Minimum Konaklama Gün Sayısı"
                className="form-control"
                type="text"
                name="min_duration_date"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.min_duration_date}
              />

              <Field
                title="Temizlik Ücreti"
                className="form-control"
                type="text"
                name="cleaning_service_price"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.cleaning_service_price}
              />
              <Field
                title="Ücretsiz Temizlik Ücreti Min Gün Sayısı"
                className="form-control"
                type="text"
                name="free_cleaning_service_min_date"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.free_cleaning_service_min_date}
              />
              <Field
                title="Ön Ödeme Oranı (%)"
                className="form-control"
                type="text"
                name="partial_paid_rate"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.partial_paid_rate}
              />
              <Select
                title="Fiyatlandırma Durumu"
                name="status"
                defaultValue={state.entity.status}
                options={this.pricesStatus}
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            {this.props.itemIdForUpdate !== "add" ? (
              <button
                type="button"
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction("");
                    }
                  });
                }}
                className="btn btn-danger btn-sm"
              >
                <span className="fa fa-trash"></span>
                Sil
              </button>
            ) : (
              <></>
            )}
            <button type="submit" className="btn btn-success btn-sm">
              <span className="fa fa-floppy-save"></span>
              Kaydet
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export { PricesEditModal };
