import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  FormLabel,
  Button,
  FormCheck,
} from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";

// styles
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  PropertyFormKeys,
  PropertyPostData,
  SelectListItem,
} from "../core/_models";
import {
  getPropertyFromId,
  getCategoriesOptionsList,
  getFeaturesOptionsList,
  getUsersOptionsList,
  getPropertyFormKeys,
  postPropertyForm,
  getRegionsOptionsList,
} from "../core/_requests";
import { useNavigate, useParams } from "react-router-dom";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { set } from "react-hook-form";

function PropertyEdit() {
  const { property_id } = useParams();
  const navigate = useNavigate();
  const [regionOptionsList, setRegionOptionsList] = useState<SelectListItem[]>(
    []
  );
  const [categoriesOptionsList, setCategoriesOptionsList] = useState<
    SelectListItem[]
  >([]);
  const [featuresOptionsList, setFeaturesOptionsList] = useState<
    SelectListItem[]
  >([]);
  const [usersOptionsList, setUsersOptionsList] = useState<SelectListItem[]>(
    []
  );
  const [propertyFormKeys, setPropertyFormKeys] = useState<PropertyFormKeys>();
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [selectedOwner, setSelectedOwner] = useState([0]);
  const [selectedType, setSelectedType] = useState("");
  const [location, setLocation] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [district, setDistrict] = useState("");
  const [selectedCapacity, setSelectedCapacity] = useState("");
  const [selectedBedroomCount, setSelectedBedroomCount] = useState(0);
  const [selectedBathroomCount, setSelectedBathroomCount] = useState(0);
  const [selectedFeatures, setSelectedFeatures] = useState<number[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");
  const [description, setDescription] = useState("");
  const [weight, setWeight] = useState(0);
  const [superTag, setSuperTag] = useState("");
  const [deposit, setDeposit] = useState("");
  const [freeCleaningServiceDate, setFreeCleaningServiceDate] = useState(0);

  const fetchPropertyData = async () => {
    if (property_id === "add") {
      return null;
    } else {
      const property = await getPropertyFromId(property_id);

      if (property) {
        setName(property.name);
        setSelectedOwner(property.users);
        setSelectedType(property.type);
        setLocation(property.location);
        setSelectedRegion(property.region);
        setDistrict(property.district);
        setSelectedCapacity(property.capacity);
        setSelectedBedroomCount(property.bedroom_count);
        setSelectedBathroomCount(property.bathroom_count);
        setSelectedFeatures(property.features);
        setSelectedCategories(property.categories);
        setSelectedStatus(property.status);
        setDescriptionTitle(property.description_title);
        setDescription(property.description);
        setWeight(property.weight);
        setSuperTag(property.super_tag);
        setDeposit(property.deposit);
        setFreeCleaningServiceDate(property.free_cleaning_service_min_date);
      }
    }
  };

  const fetchRegionOptionsList = async () => {
    const regions = await getRegionsOptionsList();
    setRegionOptionsList(regions);
  };

  const fetchCategoriesOptionsList = async () => {
    const categories = await getCategoriesOptionsList();
    setCategoriesOptionsList(categories);
  };

  const fetchFeaturesOptionsList = async () => {
    const features = await getFeaturesOptionsList();
    setFeaturesOptionsList(features);
  };

  const fetchUsersOptionsList = async () => {
    const users = await getUsersOptionsList();
    setUsersOptionsList(users);
  };

  const fetchPropertyFormKeys = async () => {
    const keys = await getPropertyFormKeys();
    setPropertyFormKeys(keys);
  };

  useEffect(() => {
    fetchPropertyData();
    fetchRegionOptionsList();
    fetchCategoriesOptionsList();
    fetchFeaturesOptionsList();
    fetchUsersOptionsList();
    fetchPropertyFormKeys();
  }, []);

  const isMobile = window.innerWidth < 990;

  const propertyTypes = [
    { value: "villa", label: "Villa" },
    { value: "hotel", label: "Otel" },
    { value: "pansion", label: "Pansiyon" },
  ];

  const propertyCapacities = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "0", label: "10+" },
  ];

  const countOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];

  const propertyStatus = [
    { value: "1", label: "Aktif" },
    { value: "2", label: "Pasif" },
  ];

  const handleNameChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setName(event.target.value);
  };

  const handleOwnerChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedOwner([Number(event.target.value)]);
  };

  const handleTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedType(event.target.value);
  };
  const handleSelectAll = () => {
    const allChecked =
      selectedCategories.length === categoriesOptionsList.length;
    setSelectedCategories(
      allChecked ? [] : categoriesOptionsList.map((cat) => Number(cat.value))
    );
  };

  const handleSelectAllFeatures = () => {
    const allChecked = selectedFeatures.length === featuresOptionsList.length;
    setSelectedFeatures(
      allChecked ? [] : featuresOptionsList.map((cat) => Number(cat.value))
    );
  };

  const handleLocationChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLocation(event.target.value);
  };
  const handleDistrictChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDistrict(event.target.value);
  };
  const handleRegionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedRegion(event.target.value);
  };

  const handleCapacityChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedCapacity(event.target.value);
  };

  const handleBedroomCountChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedBedroomCount(Number(event.target.value));
  };

  const handleBathroomCountChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedBathroomCount(Number(event.target.value));
  };
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleDescriptionChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContent);
    setDescription(htmlContent);
  };

  const handleFeatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedFeatures([...selectedFeatures, Number(value)]);
    } else {
      setSelectedFeatures(
        selectedFeatures.filter((feature) => feature !== Number(value))
      );
    }
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (checked) {
      setSelectedCategories([...selectedCategories, Number(value)]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== Number(value))
      );
    }
  };

  const handleStatusChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedStatus(event.target.value);
  };

  const handleDescriptionTitleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDescriptionTitle(event.target.value);
  };

  const handleWeightChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setWeight(Number(event.target.value));
  };

  const handleSuperTagChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSuperTag(event.target.value);
  };

  const handleDepositChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDeposit(event.target.value);
  };

  const handleFreeCleaningServiceDateChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setFreeCleaningServiceDate(Number(event.target.value));
  };

  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const data: PropertyPostData = {
        properties: {
          name: name,
          super_tag: superTag,
          type: selectedType,
          description_title: descriptionTitle,
          description: description,
          status: selectedStatus,
          region: selectedRegion,
          district: district,
          location: location,
          capacity: selectedCapacity,
          bedroom_count: selectedBedroomCount,
          bathroom_count: selectedBathroomCount,
          weight: weight,
          deposit: deposit,
          free_cleaning_service_min_date: freeCleaningServiceDate,
          users: selectedOwner,
          categories: selectedCategories as [],
          features: selectedFeatures as [],
          property_images: [],
        },
        form_id: propertyFormKeys ? propertyFormKeys.form_id || "" : "",
        form_build_id: propertyFormKeys
          ? propertyFormKeys.form_build_id || ""
          : "",
        form_token: propertyFormKeys ? propertyFormKeys.form_token || "" : "",
      };
      try {
        const response = await postPropertyForm(property_id, data);
        setTimeout(() => {
          if (response.data.data.success) {
            window.location.reload();
          }
        }, 2000);
      } catch (error) {
        console.error("Form submission error:", error);
      }
    }
    setValidated(true);
  };

  return (
    <>
      <Form
        method="POST"
        action="deneme"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Row>
          <Col lg={6}>
            <Row
              style={{
                height: "100%",
                ...(isMobile ? {} : { marginRight: "10px" }),
              }}
            >
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    Genel
                  </h5>
                  <FormGroup className="mb-3" controlId="nameValidation">
                    <FormLabel>Mülk Adı</FormLabel>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Mülk Adı"
                      required={true}
                      onChange={handleNameChange}
                      value={name}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk adını girin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="superTagValidation">
                    <FormLabel>Süper Etiket</FormLabel>
                    <Form.Control
                      type="text"
                      name="super_tag"
                      placeholder="Mülk Süper Etiket"
                      required={true}
                      onChange={handleSuperTagChange}
                      value={superTag}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen süper etiketi girin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="ownerValidation">
                    <FormLabel>Mülk Sahibi</FormLabel>
                    <Form.Control
                      as="select"
                      name="owner"
                      placeholder="Mülk Sahibi Seçin"
                      className="react-select react-select-container"
                      prefix="react-select"
                      multiple={false}
                      required={true}
                      onChange={handleOwnerChange}
                      value={selectedOwner[0]}
                    >
                      <option value="">Mülk Sahibi Seçin</option>
                      {usersOptionsList.map((propOwner) => (
                        <option key={propOwner.value} value={propOwner.value}>
                          {propOwner.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Lütfen bir sahip seçin.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <FormLabel>Mülk Türü</FormLabel>
                    <Form.Control
                      as="select"
                      name="type"
                      placeholder="Mülk Türünü Seçin"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleTypeChange}
                      value={selectedType}
                    >
                      <option value="">Mülk Türünü Seçin</option>
                      {propertyTypes.map((propType) => (
                        <option key={propType.value} value={propType.value}>
                          {propType.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk türünü seçin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <FormLabel>Mülk Bölgesi</FormLabel>
                    <Form.Control
                      as="select"
                      name="region"
                      placeholder="Mülk Bölgesini Seçin"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleRegionChange}
                      value={selectedRegion}
                    >
                      <option value="">Mülk Bölgesini Seçin</option>
                      {regionOptionsList.map((propRegion) => (
                        <option key={propRegion.value} value={propRegion.value}>
                          {propRegion.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk bölgesini seçin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="districtValidation">
                    <FormLabel>İlçe</FormLabel>
                    <Form.Control
                      type="text"
                      name="district"
                      placeholder="Mülk İlçesi"
                      required={true}
                      onChange={handleDistrictChange}
                      value={district}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk ilçesini girin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="locationValidation">
                    <FormLabel>Mülk Konumu</FormLabel>
                    <Form.Control
                      type="text"
                      name="location"
                      placeholder="Mülk Konumu"
                      required={true}
                      onChange={handleLocationChange}
                      value={location}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk konumunu girin.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <FormLabel>Mülk Kapasitesi</FormLabel>
                    <Form.Control
                      as="select"
                      name="capacity"
                      placeholder="Mülk Kapasitesini Seçin"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleCapacityChange}
                      value={selectedCapacity}
                    >
                      <option value="">Select Property Capacity</option>
                      {propertyCapacities.map((propCapacity) => (
                        <option
                          key={propCapacity.value}
                          value={propCapacity.value}
                        >
                          {propCapacity.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select property capacity.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <FormLabel>Property Bedroom Count</FormLabel>
                    <Form.Control
                      as="select"
                      name="bedroom_count"
                      placeholder="Select Property Bedroom Count"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleBedroomCountChange}
                      value={selectedBedroomCount}
                    >
                      <option value="">Select Property Bedroom Count</option>
                      {propertyCapacities.map((propCapacity) => (
                        <option
                          key={propCapacity.value}
                          value={propCapacity.value}
                        >
                          {propCapacity.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select property bedroom count.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <FormLabel>Property Bathroom Count</FormLabel>
                    <Form.Control
                      as="select"
                      name="bahtroom_count"
                      placeholder="Select Property Bathroom Count"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleBathroomCountChange}
                      value={selectedBathroomCount}
                    >
                      <option value="">Select Property Bathroom Count</option>
                      {propertyCapacities.map((propCapacity) => (
                        <option
                          key={propCapacity.value}
                          value={propCapacity.value}
                        >
                          {propCapacity.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select property bathroom count.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <FormLabel className="mb-2">Property Status</FormLabel>
                    <div className="">
                      {propertyStatus.map((propStatus) => (
                        <div key={propStatus.value}>
                          <Form.Check
                            type="radio"
                            id={propStatus.value}
                            name="status"
                            value={propStatus.value}
                            className="me-1"
                            label={propStatus.label}
                            required={true}
                            feedback="Please select property status."
                            feedbackType="invalid"
                            checked={"" + selectedStatus === propStatus.value}
                            onChange={handleStatusChange}
                          />
                        </div>
                      ))}
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="weightValidation">
                    <FormLabel>Mülk Sıralaması</FormLabel>
                    <Form.Control
                      type="text"
                      name="weight"
                      placeholder="Property Weight"
                      required={true}
                      onChange={handleWeightChange}
                      value={weight}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk sıralamasını girin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <FormLabel>Free Temizlik Ücreti Min Gün Sayısı</FormLabel>
                    <Form.Control
                      as="select"
                      name="free_cleaning_service_min_date"
                      placeholder="Select Property Free Cleaning Service Min Date"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleFreeCleaningServiceDateChange}
                      value={freeCleaningServiceDate}
                    >
                      <option value="">
                        Free Temizlik Ücreti Min Gün Sayısı
                      </option>
                      {countOptions.map((count) => (
                        <option key={count.value} value={count.value}>
                          {count.label}
                        </option>
                      ))}
                    </Form.Control>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="depositValidation">
                    <FormLabel>Depozito</FormLabel>
                    <Form.Control
                      type="text"
                      name="deposit"
                      placeholder="Property Deposit"
                      required={true}
                      onChange={handleDepositChange}
                      value={deposit}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk depozitosunu girin.
                    </Form.Control.Feedback>
                  </FormGroup>
                </Card.Body>
              </Card>
            </Row>
          </Col>
          <Col lg={6}>
            <Row>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2 d-flex justify-content-between align-items-center">
                    Mülk Özellikleri
                    <Button
                      variant={
                        selectedFeatures.length === featuresOptionsList.length
                          ? "danger"
                          : "success"
                      }
                      size="sm"
                      onClick={handleSelectAllFeatures}
                      style={{ marginLeft: "auto" }}
                    >
                      {selectedFeatures.length === featuresOptionsList.length
                        ? "Temizle"
                        : "Hepsini Seç"}
                    </Button>
                  </h5>
                  <div
                    className="features"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      maxHeight: "100px",
                    }}
                  >
                    {featuresOptionsList.map((propFeature) => (
                      <FormCheck
                        key={propFeature.value}
                        multiple={true}
                        type="checkbox"
                        id={propFeature.value}
                        name="features"
                        className="me-1"
                        value={propFeature.value}
                        label={propFeature.label}
                        onChange={handleFeatureChange}
                        checked={selectedFeatures.includes(
                          Number(propFeature.value)
                        )}
                      />
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Row>
            <Row>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2 d-flex justify-content-between align-items-center">
                    Mülk Kategorileri
                    <Button
                      variant={
                        selectedCategories.length ===
                        categoriesOptionsList.length
                          ? "danger"
                          : "success"
                      }
                      size="sm"
                      onClick={handleSelectAll}
                      style={{ marginLeft: "auto" }}
                    >
                      {selectedCategories.length ===
                      categoriesOptionsList.length
                        ? "Temizle"
                        : "Hepsini Seç"}
                    </Button>
                  </h5>

                  <div
                    className="category"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      maxHeight: "100px",
                    }}
                  >
                    {categoriesOptionsList.map((propCategory) => (
                      <FormCheck
                        key={propCategory.value}
                        multiple={true}
                        type="checkbox"
                        id={propCategory.value}
                        name="categories"
                        className="me-1"
                        value={propCategory.value}
                        label={propCategory.label}
                        onChange={handleCategoryChange}
                        checked={selectedCategories.includes(
                          Number(propCategory.value)
                        )}
                      />
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Row>
            <Row>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                    Mülk Açıklaması
                  </h5>

                  <FormGroup
                    className="mb-3"
                    controlId="descriptionTitleValidation"
                  >
                    <FormLabel>Mülk Açıklama Başlığı</FormLabel>
                    <Form.Control
                      type="text"
                      name="description_title"
                      placeholder="Property Description Title"
                      required={true}
                      onChange={handleDescriptionTitleChange}
                      value={descriptionTitle}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfe mülk açıklama başlığını girin.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={handleDescriptionChange}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName border border-1"
                      editorClassName="editorClassName px-2"
                      placeholder="Property Description"
                      editorStyle={{ minHeight: "200px" }}
                    />
                  </FormGroup>
                </Card.Body>
              </Card>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mb-3">
              <Button
                className="btn w-sm btn-secondary waves-effect me-1"
                onClick={() => {
                  navigate("/properties/list");
                }}
              >
                <i
                  className="fas fa-chevron-left"
                  style={{ marginRight: "5px" }}
                ></i>
                Geri
              </Button>
              <Button
                className="btn w-sm btn-success waves-effect waves-light me-1"
                type="submit"
              >
                Save
              </Button>
              <Button className="btn w-sm btn-danger waves-effect waves-light me-1">
                Delete
              </Button>
              {property_id !== "add" && (
                <Button
                  className="btn w-sm btn-primary waves-effect waves-light"
                  style={{ float: "right" }}
                  onClick={() => {
                    navigate("/property_images/order/" + property_id);
                  }}
                >
                  <i className="fa fa-plus"></i> Mülk Resimleri
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default PropertyEdit;
