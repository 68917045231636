import Table from "../../../utils/ResultViewer/Table";

class CategoryTable extends Table {
  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key === "category_image") {
      let imageUrl = row[key]
        ? process.env.REACT_APP_API_BASE_URL + "files/uploaded/" + row[key]
        : "";
      return (
        <td key={colIndex}>
          <div>
            <img
              src={imageUrl}
              alt={row["name"]}
              style={{ maxHeight: "10%", maxWidth: "10%" }}
            />
          </div>
        </td>
      );
    } else {
      return super.renderRow(row, key, colIndex);
    }
  }
}

export default CategoryTable;
