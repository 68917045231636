import { APICore } from "./apiCore";

const api = new APICore();

// account
function getTokenForLogin() {
  const baseUrl = "/api/v1/auth/login";
  return api.get(`${baseUrl}`, {});
}

function login(params: { username: string; password: string; remember_me: number; form_id: string; form_build_id: string; form_token: string; }) {
  const baseUrl = "/api/v1/auth/login";
  return api.create(`${baseUrl}`, params);
}

function logout() {
  const baseUrl = "/api/v1/auth/logout";
  return api.get(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

export { getTokenForLogin, login, logout, signup, forgotPassword };
