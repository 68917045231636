import { Form } from 'react-bootstrap'
import Element, { BaseProps } from './Element'
import { FormCheckType } from 'react-bootstrap/FormCheck'
import { ChangeEventHandler } from 'react'

type SelectProps = BaseProps & {
  name: string
  type?: FormCheckType
  options: string[]
  checked: boolean
  onChange: ChangeEventHandler
}

export default function Check({ options, ...props }: SelectProps) {
  return (
    <Element title={props.title} required={props.required}>
      <div className='mb-3'>
        {options.map((option, i) => (
          <Form.Check
            name={props.name}
            key={`key_${option}_${i}`}
            inline
            label={option}
            type={props.type}
            checked={props.checked}
            id={`inline-${props.type}-${option}`}
            onChange={(event) => {
              props.onChange(event)
            }}
          />
        ))}
      </div>
    </Element>
  )
}

Check.defaultProps = {
  type: 'checkbox',
}
