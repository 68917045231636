import { useEffect, useRef, useState } from 'react'
import Field, { FieldProps } from './Field'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRange, DateRangeProps, Range, RangeKeyDict } from 'react-date-range'
import moment from 'moment'
import { BaseProps } from './Element'

type DateRangeSelectorProps = FieldProps &
  DateRangeProps & {
    seperator: '&' | 'to'
    rangeSelected?: (range: Range) => void
  }

export function DateRangeSelector(props: DateRangeSelectorProps) {
  const [open, setOpen] = useState(false)
  const [range, setRange] = useState<any>(
    props.ranges
      ? props.ranges[0]
      : {
          startDate: moment().toDate(),
          endDate: moment().toDate(),
        }
  )
  const [value, setValue] = useState<any>('')
  const refCalendar = useRef<any>(null)

  const hideOnEscape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setOpen(false)
    }
  }
  const hideOnClickOutside = (e: MouseEvent) => {
    if (refCalendar.current && !refCalendar.current.contains(e.target as Node)) {
      setOpen(false)
    }
  }

  const applySelectedRange = () => {
    setValue(
      `${moment(range.startDate).format('DD-MM-YYYY')} ${props.seperator} ${moment(
        range.endDate
      ).format('DD-MM-YYYY')}`
    )
    if (props.rangeSelected) {
      props.rangeSelected(range)
    }
    setOpen(false)
  }

  useEffect(() => {
    document.addEventListener('keydown', hideOnEscape, true)
    document.addEventListener('click', hideOnClickOutside, true)
  }, [])

  return (
    <div>
      <Field
        onClick={() => setOpen(true)}
        title={props.title}
        readOnly={true}
        autoComplete='off'
        value={value}
      >
        <span
          className='position-absolute end-0 top-50 mt-1 me-3'
          style={{
            cursor: 'pointer',
          }}
          onClick={() => setOpen(true)}
        >
          <i className='fa fa-calendar fs-4'></i>
        </span>
      </Field>
      <div ref={refCalendar} className='position-absolute z-index-2 d-flex flex-column'>
        {open ? (
          <>
            <DateRange
              onChange={(rangesByKey: RangeKeyDict) => {
                setRange(rangesByKey.selection)
              }}
              ranges={[
                {
                  key: 'selection',
                  ...range,
                },
              ]}
              weekStartsOn={1}
              months={1}
              direction='horizontal'
            />
            <button
              className='btn btn-primary'
              onClick={(e) => {
                e.stopPropagation()
                applySelectedRange()
              }}
            >
              Apply
            </button>
          </>
        ) : (
          <></>
        )}
      </div>
      {props.children}
    </div>
  )
}

type DateRangeInputProps = BaseProps & {
  name: string
  seperator: '&' | 'to'
  onChange: Function
  ranges?: Range[]
}
export function DateRangeInput(props: DateRangeInputProps) {
  const [range, setRange] = useState<any>(
    props.ranges
      ? props.ranges[0]
      : {
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
  )
  return (
    <DateRangeSelector
      title={props.title}
      seperator={props.seperator}
      ranges={[range]}
      rangeSelected={(range: Range) => {
        setRange(range)
        if (props.onChange) {
          let event = {
            target: {
              name: props.name,
              value: `${moment(range.startDate).format('YYYY-MM-DD')} ${props.seperator} ${moment(
                range.endDate
              ).format('YYYY-MM-DD')}`,
            },
          }
          props.onChange(event)
        }
      }}
    />
  )
}
